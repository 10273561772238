import React, { useState, useEffect } from 'react'
import { Formik, Form, Field } from 'formik'
import { Link } from 'gatsby'
import * as Api from '../utils/api'
import Input from './Input'
import Textarea from './Textarea'
import Checkbox from './Checkbox'
import RadioList from './RadioList'
import TelephoneInput from './TelephoneInput'
import Button from './Button'
import Hero from './Hero'
import { FaArrowRight } from 'react-icons/fa'
import ServerError from './ServerError'
import { hasError } from '../utils/form'
import { getSearchParams } from 'gatsby-query-params'

export default function ContactUsForm({ setIsSubmitted }) {
  const initialValues = {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    contact_account_type: '',
    company: '',
    jobtitle: '',
    brand: '',
    website_inquiry_message: '',
    academic_study_description: '',
    study_length: '',
    study___of_devices: '',
    opt_in_to_receive_marketing_emails: true,
    mapleSyrup: '',
  }

  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const organizationTypeOptions = [
    {
      text: 'Pharmaceutical, commercial',
      value: 'commercial',
      key: 'commercial',
    },
    {
      text: 'Pharmaceutical clinical trial and/or CRO',
      value: 'clinical_trials',
      key: 'clinical_trials',
    },
    {
      text: 'Pharmacy or health system',
      value: 'pharmacy_health_system',
      key: 'pharmacy_health_system',
    },
    {
      text: 'Health plan or payer',
      value: 'health_plan_payer',
      key: 'health_plan_payer',
    },
    {
      text: 'Researcher',
      value: 'researcher',
      key: 'researcher',
    },
    {
      text: 'Other',
      value: 'other',
      key: 'other',
    },
  ]

  const studyLengthOptions = [
    {
      text: '1-12 months',
      value: '1-12 months',
      key: '1-12 months',
    },
    {
      text: '13-24 months',
      value: '13-24 months',
      key: '13-24 months',
    },
    {
      text: '25-36 months',
      value: '25-36 months',
      key: '25-36 months',
    },
    {
      text: 'Longer than 3 years',
      value: 'Longer than 3 years',
      key: 'Longer than 3 years',
    },
  ]

  const studyOfDevicesOptions = [
    {
      text: '1-50',
      value: '1-50',
      key: '1-50',
    },
    {
      text: '51-100',
      value: '51-100',
      key: '51-100',
    },
    {
      text: '101-500',
      value: '101-500',
      key: '101-500',
    },
    {
      text: '501+',
      value: '501+',
      key: '501+',
    },
  ]

  const isAcademicOrganization = (organizationType) => organizationType === 'researcher'

  const academicOrganizationFields = (errors, submitCount) => (
    <>
      <Textarea
        name="academic_study_description"
        label="Brief description of study"
        hasError={hasError('academic_study_description', submitCount, errors)}
      />
      <RadioList
        name="study_length"
        label="Length of study"
        options={studyLengthOptions}
        hasError={hasError('study_length', submitCount, errors)}
      />
      <RadioList
        name="study___of_devices"
        label="Number of smart devices needed"
        options={studyOfDevicesOptions}
        hasError={hasError('study___of_devices', submitCount, errors)}
      />
    </>
  )

  const handleFormSubmit = async (values) => {
    try {
      setLoading(true)
      setError(null)

      // https://www.thryv.com/blog/honeypot-technique/
      // can implement on backend
      if (values.mapleSyrup) {
        setError({ message: 'Failed to submit.', code: 409 })
        return
      }
      delete values.mapleSyrup

      await Api.postPartnership(values)
      setLoading(false)
      setError(null)
      setIsSubmitted(true)

      window.scrollTo(0, 0)
    } catch (error) {
      setLoading(false)
      setError({ message: error.error, code: error.code })
    }
  }
  const validate = (values) => {
    const errors = {}

    if (!values.firstname) {
      errors.firstname = 'Please enter your first name.'
    }

    if (!values.lastname) {
      errors.lastname = 'Please enter your last name.'
    }

    if (!values.email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = "That email address doesn't look quite right."
    }

    if (!values.phone || values.phone.length !== 14) {
      errors.phone = 'Please confirm that your phone number is correct.'
    }

    if (!values.contact_account_type) {
      errors.contact_account_type = 'Please select an organization type.'
    }

    if (!values.company) {
      errors.company = 'Please enter an affiliation.'
    }

    if (!values.jobtitle) {
      errors.jobtitle = 'Please enter your job title.'
    }

    if (!isAcademicOrganization(values.contact_account_type)) {
      if (!values.website_inquiry_message) {
        errors.website_inquiry_message = 'Please enter how we can partner.'
      }
    }

    if (values.contact_account_type !== 'other' && !isAcademicOrganization(values.contact_account_type)) {
      if (!values.brand) {
        errors.brand = 'Please enter the medication type/brand interested in support.'
      }
    }

    if (isAcademicOrganization(values.contact_account_type)) {
      if (!values.academic_study_description) {
        errors.academic_study_description = 'Description required'
      }

      if (!values.study_length) {
        errors.study_length = 'Study length required'
      }

      if (!values.study___of_devices) {
        errors.study___of_devices = 'Value required'
      }
    }

    return errors
  }

  return (
    <>
      <Hero color="blue" image="data-devices" heading="Let's partner!" />
      <div className="page-section">
        <div className="form-container">
          <h2 className="header">
            If you are a healthcare organization that wants to learn how AdhereTech can support your patient population
            to achieve optimal adherence, complete the form below.
          </h2>

          <span className="subtext">
            For patients, please contact us through <Link to="/patient-support">Patient Support</Link> or{' '}
            <Link to="/how-to-get-aidia">How To Get Aidia</Link>.
          </span>
          <span className="subtext">Provide the following information and a representative will be in touch.</span>
          <Formik
            initialValues={initialValues}
            validate={(values) => validate(values)}
            onSubmit={async (values) => await handleFormSubmit(values)}
          >
            {({ values, isSubmitting, isValid, errors, submitCount, setFieldValue }) => {
              const queryParamOrganizationType = getSearchParams().organizationType
              useEffect(() => {
                if (queryParamOrganizationType) {
                  setFieldValue('contact_account_type', queryParamOrganizationType)
                }
              }, [queryParamOrganizationType])

              return (
                <Form>
                  <fieldset>
                    <Input name="firstname" label="First name" hasError={hasError('firstname', submitCount, errors)} />
                    <Input name="lastname" label="Last name" hasError={hasError('lastname', submitCount, errors)} />
                    <Input
                      name="email"
                      label="Email address"
                      type="email"
                      hasError={hasError('email', submitCount, errors)}
                    />

                    <TelephoneInput
                      name="phone"
                      label="Phone number (number to contact)"
                      hasError={hasError('phone', submitCount, errors)}
                      previousValue={values.phone}
                      setFieldValue={setFieldValue}
                    />
                    <RadioList
                      name="contact_account_type"
                      label="Organization type"
                      options={organizationTypeOptions}
                      hasError={hasError('contact_account_type', submitCount, errors)}
                    />

                    <Input
                      name="company"
                      label="Company/institutional Affiliation"
                      hasError={hasError('company', submitCount, errors)}
                    />
                    <Input name="jobtitle" label="Job title" hasError={hasError('jobtitle', submitCount, errors)} />

                    {values.contact_account_type !== 'other' &&
                      !isAcademicOrganization(values.contact_account_type) && (
                        <Input
                          name="brand"
                          label="Medication type/brand interested in adherence support"
                          hasError={hasError('brand', submitCount, errors)}
                        />
                      )}
                    {!isAcademicOrganization(values.contact_account_type) && (
                      <Textarea
                        name="website_inquiry_message"
                        label={
                          values.contact_account_type === 'other'
                            ? 'Please describe your interest in a program'
                            : 'How Can We Partner?'
                        }
                        hasError={hasError('website_inquiry_message', submitCount, errors)}
                      />
                    )}
                    {isAcademicOrganization(values.contact_account_type) &&
                      academicOrganizationFields(errors, submitCount)}
                    <Checkbox
                      name="opt_in_to_receive_marketing_emails"
                      label={
                        <span>
                          YES, I agree to receive business information from AdhereTech about products and services. You
                          can unsubscribe at any time by emailing{' '}
                          <a href="mailto:unsubscribe@adheretech.com?subject=Unsubscribe from AdhereTech communications">
                            unsubscribe@adheretech.com
                          </a>{' '}
                          or following the unsubscribe instructions on the communication.
                        </span>
                      }
                    />

                    <Field type="text" name="mapleSyrup" />
                  </fieldset>
                  <Button color="blue" type="submit" disabled={isSubmitting || (submitCount > 0 && !isValid)}>
                    <span>Submit</span>
                    <FaArrowRight className="arrow-right" />
                  </Button>
                </Form>
              )
            }}
          </Formik>
          {error && <ServerError />}
        </div>
      </div>
    </>
  )
}
