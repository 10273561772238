import React, { useState } from 'react'
import ContactUsConfirmation from '../components/ContactUsConfirmation'
import ContactUsForm from '../components/ContactUsForm'

export default function contactUsFormPage() {
  const [isSubmitted, setIsSubmitted] = useState(false)

  if (isSubmitted) {
    return (
      <main>
        <ContactUsConfirmation />
      </main>
    )
  }

  return (
    <main>
      <ContactUsForm setIsSubmitted={setIsSubmitted} />
    </main>
  )
}
