import React from 'react'
import FormConfirmation from '../components/FormConfirmation'
import ActionBanner from '../components/ActionBanner'

export default function ContactUsConfirmation() {
  return (
    <div className="form-confirmation__container contact-us">
      <div className="page-section">
        <FormConfirmation centered>
          <p>We have received your request and a representative will reach out to you shortly.</p>
        </FormConfirmation>
      </div>
      <ActionBanner
        header={null}
        content="Meanwhile, take a closer look at the AdhereTech real-world research."
        linkPath="/evidence"
        linkText="View our Evidence"
      />
    </div>
  )
}
